// Here you can add other styles
table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  td, th {
    border: 1px solid #0e0d0d;
    text-align: left;
    // padding: 8px;
  }
  
  tr:nth-child(even) {
    // background-color: #dddddd;
  }



  @font-face {
    font-family: 'MyFont';
    src: local('MyFont'), url('../assets/fonts/BOOKOS.TTF') format('TTF');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

body {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        // background-color: #FAFAFA;
        // font: 12pt "Tahoma";
    }
    * {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
    }
    .page {
        width: 210mm;
        min-height: 330mm;
        margin: 10mm auto;
        border: 1px #D3D3D3 solid;
        border-radius: 5px;
        background: white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }
    
 
    
    @page {
        size: A4;
        margin: 0;
    }
    @media print {
      #printPageButton {
        display: none;
      }
        .page {
            margin: 0;
            border: initial;
            border-radius: initial;
            width: initial;
            min-height: initial;
            box-shadow: initial;
            background: initial;
            page-break-after: always;
        }
    }
